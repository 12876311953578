import React from "react";
import Header from "../components/Header/Header";
import Hero from "../components/Hero/Hero";
import AboutMe from "../components/AboutMe/AboutMe";
import Services from "../components/Services/Services";
import Portfolio from "../components/Portfolio/Portfolio";
import Contact from "../components/Contact/Contact";

const Home = ({ isDarkMode, toggleTheme }) => {
  return (
    <div>
      <Header isDarkMode={isDarkMode} toggleTheme={toggleTheme} />
      <Hero />
      <AboutMe />
      <Services />
      <Portfolio />
      <Contact/>
    </div>
  );
};

export default Home;
