import styled from "styled-components";

export const AboutMeWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.dark};
  padding: 65px 0;
  overflow: hidden;
  position: relative;
  &:after {
    content: "";
    width: 234px;
    height: 234px;
    background-color: ${({ theme }) => theme.colors.red};
    position: absolute;
    bottom: -180px;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 0;
    border-radius: 50%;
  }
`;

export const AboutMeContainer = styled.div`
  color: ${({ theme }) => theme.colors.whiteAbout};
  margin: 0 auto;
  max-width: 1210px;
  position: relative;
  z-index: 1;
`;

export const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  h3 {
    font-size: 36px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.whiteAbout};
    transition: all 0.3s;
  }
`;
export const ParagraphTitle = styled.div`
  p {
    font-size: 18px;
    text-align: center;
    padding: 0 120px;

    @media screen and (max-width: 1210px) {
      padding: 0 20px;
    }
  }
`;
export const WrapperCounter = styled.div`
  display: flex;

  @media screen and (max-width: 1210px) {
    flex-direction: column;
    align-items: center;
  }
`;
export const SectionCounter = styled.div`
  width: 33.33%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media screen and (max-width: 1210px) {
    width: 100%;
  }
`;
export const NumberPlus = styled.div`
  font-size: 64px;
  margin: 40px 0 20px 0;
  color: ${({ theme }) => theme.colors.pink};
  font-weight: bold;
`;
export const TextNumber = styled.p`
  font-size: 18;
  font-weight: 600;
`;
