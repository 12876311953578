import React from "react";
import {
  HeroWrapper,
  HeroContainerWrapper,
  LeftSection,
  RightSection,
  ParagraphMini,
  HelloText,
  ParagraphIntro,
  ButtonPortfolio,
  BigF,
  BigE,
  ShapeOne,
  Arrow,
} from "./Hero.style";
import ShapeO from "../../images/shape_117.png";
import ArrowImg from "../../images/arrow.png";
const Hero = () => {
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <HeroWrapper>
      <HeroContainerWrapper>
        <LeftSection>
          {/* <ParagraphMini>Strony internetowe oraz sklepy!</ParagraphMini> */}
          <HelloText>
            Cześć, jestem
            <br /> <span>Frontend Developerem</span>
          </HelloText>
          <ParagraphIntro>
            Tworzę nowoczesne strony internetowe i sklepy online, które
            wyróżniają się na tle konkurencji. Odkryj moje portfolio i zobacz,
            jak mogę pomóc rozwinąć Twój biznes w sieci.
          </ParagraphIntro>
          <ButtonPortfolio onClick={() => scrollToSection("portfolio")}>Zobacz portfolio</ButtonPortfolio>
        </LeftSection>
        <RightSection>
          <BigF>
            F<ShapeOne alt="shapeO" src={ShapeO}></ShapeOne>
          </BigF>
          <BigE>
            E<Arrow alt="ArrowImg" src={ArrowImg}></Arrow>
          </BigE>
        </RightSection>
      </HeroContainerWrapper>
    </HeroWrapper>
  );
};

export default Hero;
