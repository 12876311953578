import styled from "styled-components";

export const ServicesWrapper = styled.div``;

export const ContainerServicesWrapper = styled.div`
  margin: 0 auto;
  max-width: 1210px;
  position: relative;
  z-index: 1;
`;

export const HeaderTitleServices = styled.h2`
  text-align: center;
  padding-top: 64px;
  padding-bottom: 24px;
  font-size: 36px;
  font-weight: 600;
  /* color: ${({ theme }) => theme.colors.dark}; */
`;

export const ParagraphText = styled.p`
  font-size: 18px;
  text-align: center;
  /* line-height: 32px; */
  padding: 0 100px;
  margin: 30px 0 50px;

  @media screen and (max-width: 820px) {
    padding: 0 50px;
  }
`;

export const WrapperServices = styled.div`
  display: flex;
  padding-bottom: 64px;

  @media screen and (max-width: 820px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const Elem = styled.div`
  width: 25%;
  padding: 25px;

  @media screen and (max-width: 820px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
`;

export const ImageIco = styled.img``;

export const Title = styled.h3`
  @media screen and (max-width: 820px) {
    text-align: center;
  }
`;

export const Text = styled.p`
  @media screen and (max-width: 820px) {
    text-align: center;
  }
`;
