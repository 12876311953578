export const lightTheme = {
  colors: {
    red: "#FC3314",
    pink: "#FFD4D0",
    dark: "#1F1F1F",
    darkGrey: "#8D8D8D",
    mediumGrey: "#E1E1E1",
    grey: "#F7F7F7",
    white: "#FFF",
    whiteAbout: "#FFF",
    menuMobileText: "#8D8D8D",
    btnColorText: '#fff',
    btnSecond: '#fff'
  },
  fonts: {
    main: "Montserrat, sans-serif",
  },
  heading: {
    headingOne: "64px",
    headingTwo: "48px",
    headingThree: "36px",
  },
  transition: "background-color 0.5s ease, color 0.5s ease",
};

export const darkTheme = {
  colors: {
    red: "#D9042B",
    pink: "#023E73",
    dark: "#C9E9F5",
    darkGrey: "#8D8D8D",
    mediumGrey: "#E1E1E1",
    grey: "#F7F7F7",
    white: "#8D8D8D",
    whiteAbout: "#023E73",
    menuMobileText: "#FFF",
    btnColorText: "#D9042B",
    btnSecond: '#fff'
  },
  fonts: {
    main: "Montserrat, sans-serif",
  },
  heading: {
    headingOne: "64px",
    headingTwo: "48px",
    headingThree: "36px",
  },
  transition: "background-color 0.5s ease, color 0.5s ease",
};
