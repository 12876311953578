import React from "react";
import {
  AboutMeWrapper,
  AboutMeContainer,
  HeaderTitle,
  ParagraphTitle,
  WrapperCounter,
  SectionCounter,
  NumberPlus,
  TextNumber,
} from "./AboutMe.style";
import CountUp from "react-countup";
const AboutMe = () => {
  return (
    <AboutMeWrapper id="about">
      <AboutMeContainer>
        <HeaderTitle>
          <h3>O mnie</h3>
        </HeaderTitle>
        <ParagraphTitle>
          <p>
            Jestem frontend developerem z 5-letnim doświadczeniem w tworzeniu
            stron internetowych i sklepów online. Specjalizuję się w pracy z
            technologiami takimi jak JavaScript, React, Sass oraz Styled
            Components. Dzięki moim umiejętnościom, tworzę nowoczesne,
            responsywne i intuicyjne interfejsy, które zachwycają użytkowników.
          </p>
        </ParagraphTitle>
        <WrapperCounter>
          <SectionCounter>
            <NumberPlus>
              <CountUp
                start={5}
                end={64}
                duration={2.75}
                separator=" "
                decimals={0}
                enableScrollSpy={true}
              >
                {({ countUpRef }) => (
                  <div>
                    <span ref={countUpRef} />
                  </div>
                )}
              </CountUp>
            </NumberPlus>
            <TextNumber>Utworzone strony internetowe</TextNumber>
          </SectionCounter>
          <SectionCounter>
            <NumberPlus>
              <CountUp
                start={0}
                end={12}
                duration={6}
                separator=" "
                decimals={0}
                enableScrollSpy={true}
              >
                {({ countUpRef }) => (
                  <div>
                    <span ref={countUpRef} />
                  </div>
                )}
              </CountUp>
            </NumberPlus>
            <TextNumber>Utworzone sklepy internetowe</TextNumber>
          </SectionCounter>
          <SectionCounter>
            <NumberPlus>
              <CountUp
                start={0}
                end={5}
                duration={5}
                separator=" "
                decimals={0}
                suffix=" +"
                enableScrollSpy={true}
              >
                {({ countUpRef }) => (
                  <div>
                    <span ref={countUpRef} />
                  </div>
                )}
              </CountUp>
            </NumberPlus>
            <TextNumber>Lat doświadczenia</TextNumber>
          </SectionCounter>
        </WrapperCounter>
      </AboutMeContainer>
    </AboutMeWrapper>
  );
};

export default AboutMe;
