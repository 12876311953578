import styled, { keyframes }  from "styled-components";

const bounce = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
`;

export const HeroWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.pink};
`;
export const HeroContainerWrapper = styled.div`
  margin: 0 auto;
  max-width: 1210px;
  padding: 160px 0;
  display: flex;

  @media screen and (max-width: 1210px) {
    padding: 30px 0px 60px;
    width: 100%;
  }
`;

export const LeftSection = styled.div`
  width: 50%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 1210px) {
    padding: 0 20px;
    width: 100%;
  }
`;
export const RightSection = styled.div`
  position: relative;
`;
export const ParagraphMini = styled.p`
  font-size: 20px;
  color: ${({ theme }) => theme.colors.dark};
  transition: all 1s;

  @media screen and (max-width: 1210px) {
    font-size: 16px;
  }
`;
export const HelloText = styled.h2`
  color: ${({ theme }) => theme.colors.dark};
  font-size: 48px;
  transition: all 1s;
  margin: 0 0 20px 0;
  span {
    color: ${({ theme }) => theme.colors.red};
    transition: all 1s;
  }

  @media screen and (max-width: 1210px) {
    font-size: 28px;
  }
`;
export const ParagraphIntro = styled.p`
  font-size: 18px;
  color: ${({ theme }) => theme.colors.dark};
  transition: all 1s;
  margin: 0;

  @media screen and (max-width: 1210px) {
    font-size: 16px;
  }
`;
export const ButtonPortfolio = styled.button`
  padding: 14px 36px;
  margin-top: 30px;
  background-color: ${({ theme }) => theme.colors.dark};
  color: ${({ theme }) => theme.colors.btnColorText};
  transition: all 0.4s;
  border: none;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.darkGrey};
    color: ${({ theme }) => theme.colors.pink};
  }

  @media screen and (max-width: 1210px) {
    font-size: 14px;
  }
`;

export const BigF = styled.p`
  position: absolute;
  display: inline-block;
  font-size: 475.18px;
  line-height: 475.18px;
  font-weight: 800;
  margin: 0;
  color: transparent;
  -webkit-text-stroke: 2px ${({ theme }) => theme.colors.dark}; /* Obrys tekstu */
  padding: 10px 20px;
  font-family: "Inter";
  z-index: 3;
  bottom: -234px;
  left: 30px;

  @media screen and (max-width: 1270px) {
    display: none;
  }
  /* background-color: rgba(0, 0, 0, 0.5); Półprzezroczyste tło */
`;

export const ShapeOne = styled.img`
  position: absolute;
  left: 30px;
  z-index: 0;
`;

export const BigE = styled.p`
  position: absolute;
  display: inline-block;
  font-size: 475.18px;
  line-height: 475.18px;
  font-weight: 800;
  margin: 0;
  color: ${({ theme }) => theme.colors.red};
  padding: 10px 20px;
  font-family: "Inter";
  z-index: 0;
  bottom: -235px;
  left: 300px;
  transition: all 0.4s;
  @media screen and (max-width: 1270px) {
    display: none;
  }
  /* background-color: rgba(0, 0, 0, 0.5); Półprzezroczyste tło */
`;

export const Arrow = styled.img`
  position: absolute;
  left: 210px;
  bottom: 100px;
  z-index: 0;
  animation: ${bounce} 2s infinite;
`;
