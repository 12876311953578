import styled from "styled-components";

export const PortfolioWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.pink};
`;
export const ContainerPortfolio = styled.div`
  max-width: 1210px;
  margin: 0 auto;
  padding-bottom: 80px;

  /* @media screen and (max-width: 1270px) {
    display: none;
  } */
`;
export const Text = styled.div`
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;

  h3 {
    color: ${({ theme }) => theme.colors.dark};
    font-size: 36px;
    font-weight: 600;
  }

  p {
    font-size: 18px;
    /* line-height: 20px; */
    padding: 0 100px 20px 100px;
    color: ${({ theme }) => theme.colors.dark};

    
    @media screen and (max-width: 1270px) {
      padding: 0 30px 20px 30px;
    }
  }
`;
export const GalleryWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  color: ${({ theme }) => theme.colors.dark};

  @media screen and (max-width: 1270px) {
    justify-content: center;
  }

  .galleryItem {
    width: 33.3%;
    padding: 10px;

    @media screen and (max-width: 1270px) {
      padding: 20px;
      width: 50%;
    }

    @media screen and (max-width: 700px) {
      width: 100%;
      padding: 30px;
    }
  }

  .wrapperTextInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: relative;

    h3 {
      margin: 0;
      padding-top: 30px;
    }

    .tech {
      display: flex;

      p {
        padding-right: 6px;
        font-size: 14px;
      }
    }

    button {
      position: absolute;
      right: 0;
      bottom: 10px;
      background-color: ${({ theme }) => theme.colors.red};
      padding: 7px;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      border-radius: 50%;
      cursor: pointer;
      transition: all 0.3s;

      &:hover {
        background-color: ${({ theme }) => theme.colors.pink};
      }

      a {
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .imageBackground {
    width: 100%;
    min-height: 240px;
    background-size: cover;
    background-position: center;
  }
`;
