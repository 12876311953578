import React from "react";
import {
  ServicesWrapper,
  ContainerServicesWrapper,
  HeaderTitleServices,
  ParagraphText,
  WrapperServices,
  Elem,
  ImageIco,
  Title,
  Text,
} from "./Services.style.js";
import oneImage from "../../images/code-01.svg";
import shopImage from "../../images/shoppingbag.svg";
import colorImage from "../../images/colors.svg";
import mailImage from "../../images/mail.svg";

const Services = () => {
  return (
    <ServicesWrapper id="services">
      <ContainerServicesWrapper>
        <HeaderTitleServices>Usługi</HeaderTitleServices>
        <ParagraphText>
          Oferuję kompleksowe usługi tworzenia stron internetowych oraz sklepów
          online, dostosowane do Twoich potrzeb. Wykorzystuję najnowsze
          technologie, takie jak JavaScript, React, Sass, Styled Components, a
          także WordPress i WooCommerce, aby zapewnić Ci nowoczesne i efektywne
          rozwiązania.
        </ParagraphText>
        <WrapperServices>
          <Elem>
            <ImageIco width="48px" height="48px" src={oneImage} alt="ico1" />
            <Title>Strony internetowe</Title>
            <Text>
              Tworzę nowoczesne, responsywne strony internetowe, które
              przyciągają uwagę i wyróżniają się na tle konkurencji.
            </Text>
          </Elem>
          <Elem>
            <ImageIco width="48px" height="48px" src={shopImage} alt="ico2" />
            <Title>Sklepy internetowe</Title>
            <Text>
              Projektuję i wdrażam funkcjonalne sklepy internetowe, które
              zwiększają sprzedaż i zapewniają doskonałe doświadczenie zakupowe.
            </Text>
          </Elem>
          <Elem>
            <ImageIco width="48px" height="48px" src={colorImage} alt="ico3" />

            <Title>Tworzenie designów</Title>
            <Text>
              Specjalizuję się w tworzeniu nowoczesnych i intuicyjnych designów
              w Figma. Każdy projekt to unikalne połączenie estetyki i
              funkcjonalności.
            </Text>
          </Elem>
          <Elem>
            <ImageIco width="48px" height="48px" src={mailImage} alt="ico4" />
            <Title>Mailingi</Title>
            <Text>
              Tworzę skuteczne mailingi, które przyciągają uwagę i budują
              relacje z odbiorcami. Każdy e-mail to starannie opracowana treść,
              która trafia w potrzeby Twojej grupy docelowej.
            </Text>
          </Elem>
        </WrapperServices>
      </ContainerServicesWrapper>
    </ServicesWrapper>
  );
};

export default Services;
