import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: ${({ theme }) => theme.fonts.main};
    transition: ${({ theme }) => theme.transition};
  }
  * {
    box-sizing: border-box;
  }

  .container {
    max-width: 1210px;
    margin: 0 auto;
  }
`;

export default GlobalStyle;
