import React, { useState, useEffect } from "react";
import {
  HeaderWrapper,
  HeaderContainer,
  LogoWrapper,
  MenuWrapper,
  UList,
  LiElem,
  MenuMobile,
  MenuMobileList,
  UListMobile,
  LiElemMobile,
  WrapperTimes,
} from "./Header.style";
import Switch from "./Switch/Switch";
import { GiHamburgerMenu } from "react-icons/gi";
import { FaTimes } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const Header = ({ isDarkMode, toggleTheme }) => {
  const { t, i18n } = useTranslation(); // `t` i `i18n` są używane do tłumaczeń i zmiany języka
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isEnglish, setIsEnglish] = useState(i18n.language === "en");

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setIsEnglish(lng === "en");
  };

  const handleLanguageToggle = () => {
    const newLanguage = isEnglish ? "pl" : "en";
    changeLanguage(newLanguage);
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isMenuOpen]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
      setIsMenuOpen(false); // Zamknięcie menu mobilnego po kliknięciu
    }
  };

  const scrollToSectionMobile = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
      setIsMenuOpen(false); // Zamknięcie menu mobilnego po kliknięciu
    }

    toggleMenu();
  };

  return (
    <HeaderWrapper>
      <HeaderContainer>
        <LogoWrapper>
          <p>
            code<span>.Lange</span>
          </p>
        </LogoWrapper>
        <MenuWrapper>
          <UList>
            <LiElem onClick={() => scrollToSection("about")}> {t("aboutMenu")}</LiElem>
            <LiElem onClick={() => scrollToSection("services")}> {t("serviceMenu")}</LiElem>
            <LiElem onClick={() => scrollToSection("portfolio")}>
              Portfolio
            </LiElem>
            <LiElem onClick={() => scrollToSection("contact")}>Kontakt</LiElem>
            <LiElem>
              <Switch
                isDarkMode={isDarkMode}
                toggleTheme={toggleTheme}
                idx={"dn"}
              />
            </LiElem>
            {/* <LiElem>
              <div className="switch">
                <input
                  id="language-toggle"
                  className="check-toggle check-toggle-round-flat"
                  type="checkbox"
                  checked={isEnglish}
                  onChange={handleLanguageToggle}
                />
                <label htmlFor="language-toggle"></label>
                <span className="on">PL</span>
                <span className="off">EN</span>
              </div>
            </LiElem> */}
          </UList>
        </MenuWrapper>
        <MenuMobile>
          <GiHamburgerMenu onClick={toggleMenu} />
          <Switch
            isDarkMode={isDarkMode}
            toggleTheme={toggleTheme}
            idx={"dnMobile"}
          />
        </MenuMobile>
        <MenuMobileList
          style={isMenuOpen ? { display: "flex" } : { display: "none" }}
        >
          <WrapperTimes>
            <FaTimes onClick={toggleMenu} />
          </WrapperTimes>
          <UListMobile>
            <LiElemMobile onClick={() => scrollToSectionMobile("about")}>
              O mnie
            </LiElemMobile>
            <LiElemMobile onClick={() => scrollToSectionMobile("services")}>
              Usługi
            </LiElemMobile>
            <LiElemMobile onClick={() => scrollToSectionMobile("portfolio")}>
              Portfolio
            </LiElemMobile>
            <LiElemMobile onClick={() => scrollToSectionMobile("contact")}>
              Kontakt
            </LiElemMobile>
          </UListMobile>
        </MenuMobileList>
      </HeaderContainer>
    </HeaderWrapper>
  );
};

export default Header;
