import React from "react";
import {
  PortfolioWrapper,
  ContainerPortfolio,
  Text,
  GalleryWrapper,
} from "./Portfolio.style";
import { data } from "./data";
import arrow from "../../images/send.png";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const PortfolioItem = ({ item, index }) => {
  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <motion.div
      className="galleryItem"
      ref={ref}
      initial="hidden"
      animate={inView ? "visible" : "hidden"}
      variants={itemVariants}
      transition={{ duration: 0.5, delay: index * 0.2 }}
    >
      <div
        className="imageBackground"
        style={{ backgroundImage: `url(${item.image})` }}
      ></div>
      <div className="wrapperTextInfo">
        <h3>{item.name}</h3>
        <div className="tech">
          {item.tech.map((techItem, techIndex) => (
            <p key={techIndex}>{techItem}</p>
          ))}
        </div>
        <button>
          <a href={item.link}>
            <img alt="arrow" src={arrow}></img>
          </a>
        </button>
      </div>
    </motion.div>
  );
};

const Portfolio = () => {
  return (
    <PortfolioWrapper id="portfolio">
      <ContainerPortfolio>
        <Text>
          <h3>Portfolio</h3>
          <p>
            Zobacz moje najnowsze projekty, które pokazują moje umiejętności w
            tworzeniu stron internetowych, sklepów online oraz designie w Figma.
          </p>
        </Text>
        <GalleryWrapper>
          {data.map((item, index) => (
            <PortfolioItem key={index} item={item} index={index} />
          ))}
        </GalleryWrapper>
      </ContainerPortfolio>
    </PortfolioWrapper>
  );
};

export default Portfolio;
