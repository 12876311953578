// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './locales/en/translation.json';
import translationPL from './locales/pl/translation.json';

// Rozpocznij konfigurację
i18n
  .use(initReactI18next) // Dodaję `initReactI18next` do konfiguracji
  .init({
    resources: {
      en: {
        translation: translationEN
      },
      pl: {
        translation: translationPL
      }
    },
    lng: "pl", // domyślny język
    fallbackLng: "en", // język zapasowy, jeśli tłumaczenie nie jest dostępne
    interpolation: {
      escapeValue: false // React już obsługuje ucieczkę
    }
  });

export default i18n;
