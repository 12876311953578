import React, { useState } from 'react';
import GlobalStyle from "./global/GlobalStyles";
import Home from "./pages/Home";
import { ThemeProvider } from "styled-components";
import {darkTheme , lightTheme} from "./global/Theme.js";

function App() {
  const [isDarkMode, setIsDarkMode] = useState(false);

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
  };

  return (
    <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
      <div className="App">
        <GlobalStyle />
        <Home isDarkMode={isDarkMode} toggleTheme={toggleTheme} />
      </div>
    </ThemeProvider>
  );
}

export default App;
