import Mallorca from '../../images/Portfolio/Mallorca.jpeg'
import MM from '../../images/Portfolio/mmierzejewksa.jpeg'
import Heviran from '../../images/Portfolio/Heviran.jpg'
import Enterol from '../../images/Portfolio/enterol.jpg'
import Ranigast from '../../images/Portfolio/ranigast.jpg'
import Ibuvit from '../../images/Portfolio/ibuvit.jpg'
export const data = [
  {
    id: 0,
    name: "mmierzejewska",
    link: "http://mmierzejewska.com/",
    image: MM,
    tech: ["Wordpress", "ACF", "Elementor Pro"],
  },
  {
    id: 1,
    name: "Heviran",
    link: "https://www.hevirancomfort.pl/",
    image: Heviran,
    tech: ["CMS Evolution", "HTML", "CSS/Scss", "JS6", "PHP"],
  },
  {
    id: 2,
    name: "Enterol",
    link: "https://enterol.pl/",
    image: Enterol,
    tech: ["CMS Evolution", "HTML", "CSS/Scss", "JS6", "PHP"],
  },
  {
    id: 3,
    name: "Ranigast",
    link: "https://ranigast.pl/",
    image: Ranigast,
    tech: ["CMS Evolution", "HTML", "CSS/Scss", "JS6", "PHP"],
  },
  {
    id: 4,
    name: "MallorcaWeek",
    link: "https://www.mallorcaweek.pl",
    image: Mallorca,
    tech: ["CMS Evolution", "HTML", "CSS/Scss", "JS6", "PHP"],
  },
  {
    id: 5,
    name: "Ibuvit",
    link: "https://ibuvit.pl",
    image: Ibuvit,
    tech: ["Wordpress", "HTML", "CSS/Scss", "JS6", "PHP"],
  },
];
