import styled from "styled-components";

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.pink};
  position: relative;

  @media screen and (max-width: 820px) {
    &:before {
      display: none;
    }
  }

  &:before {
    width: 146px;
    height: 146px;
    background-color: ${({ theme }) => theme.colors.red};
    content: "";
    position: absolute;
    top: -43px;
    left: 55%;
    border-radius: 50%;
    transition: transform 0.3s ease;
  }
  &:hover:before {
    transform: translateY(-50%);
  }
`;

export const HeaderContainer = styled.div`
  margin: 0 auto;
  margin-top: 20px;
  width: 1210px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  @media screen and (max-width: 1210px) {
    padding: 0 20px;
  }
`;

export const LogoWrapper = styled.div`
  p {
    font-size: 30px;
    color: ${({ theme }) => theme.colors.dark};
    letter-spacing: -2px;
    font-weight: 500;
    span {
      letter-spacing: 0px;
      color: ${({ theme }) => theme.colors.red};
    }
  }
`;

export const MenuWrapper = styled.div`
  display: flex;

  .switch {
    position: relative;
    display: flex;
    align-items: center;
    margin: 0 5px;
  }

  .switch > span {
    position: absolute;
    top: 9px;
    pointer-events: none;
    font-family: "Helvetica", Arial, sans-serif;
    font-weight: bold;
    font-size: 12px;
    text-transform: uppercase;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
    width: 50%;
    text-align: center;
  }

  input.check-toggle-round-flat:checked ~ .off {
    color: #f36f25;
  }

  input.check-toggle-round-flat:checked ~ .on {
    color: #fff;
  }

  .switch > span.on {
    left: 0;
    padding-left: 2px;
    color: #f36f25;
  }

  .switch > span.off {
    right: 0;
    padding-right: 4px;
    color: #fff;
  }

  .check-toggle {
    position: absolute;
    margin-left: -9999px;
    visibility: hidden;
  }
  .check-toggle + label {
    display: block;
    position: relative;
    cursor: pointer;
    outline: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  input.check-toggle-round-flat + label {
    padding: 2px;
    width: 97px;
    height: 30px;
    background-color: #f36f25;
    -webkit-border-radius: 60px;
    -moz-border-radius: 60px;
    -ms-border-radius: 60px;
    -o-border-radius: 60px;
    border-radius: 60px;
  }
  input.check-toggle-round-flat + label:before,
  input.check-toggle-round-flat + label:after {
    display: block;
    position: absolute;
    content: "";
  }

  input.check-toggle-round-flat + label:before {
    top: 2px;
    left: 2px;
    bottom: 2px;
    right: 2px;
    background-color: #f36f25;

    -moz-border-radius: 60px;
    -ms-border-radius: 60px;
    -o-border-radius: 60px;
    border-radius: 60px;
  }
  input.check-toggle-round-flat + label:after {
    top: 4px;
    left: 4px;
    bottom: 4px;
    width: 46px;
    background-color: #fff;
    -webkit-border-radius: 52px;
    -moz-border-radius: 52px;
    -ms-border-radius: 52px;
    -o-border-radius: 52px;
    border-radius: 52px;
    -webkit-transition: margin 0.2s;
    -moz-transition: margin 0.2s;
    -o-transition: margin 0.2s;
    transition: margin 0.2s;
  }

  input.check-toggle-round-flat:checked + label {
  }

  input.check-toggle-round-flat:checked + label:after {
    margin-left: 44px;
  }

  @media screen and (max-width: 820px) {
    display: none;
  }
`;

export const UList = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;

`;

export const LiElem = styled.li`
  font-size: 18px;
  color: ${({ theme }) => theme.colors.dark};
  font-weight: 600;
  cursor: pointer;
  padding: 0 20px;
  transition: all 0.3s;

  &:hover {
    color: ${({ theme }) => theme.colors.darkGrey};
  }

  &:nth-last-child(2) {
    padding: 10px 24px;
    background-color: ${({ theme }) => theme.colors.dark};
    color: ${({ theme }) => theme.colors.btnColorText};
    transition: all 0.4s;
    margin-left: 30px;
    margin-right: 40px;

    &:hover {
      background-color: ${({ theme }) => theme.colors.darkGrey};
      color: ${({ theme }) => theme.colors.pink};
    }
  }
`;

export const MenuMobile = styled.div`
  display: none;
  position: relative;

  @media screen and (max-width: 820px) {
    display: flex;
    align-items: center;
    svg {
      width: 28px;
      height: 28px;
      cursor: pointer;
      fill: ${({ theme }) => theme.colors.menuMobileText};
    }
  }
`;

export const MenuMobileList = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: 100vh;
  top: -20px;
  left: 0;
  z-index: 20;
  background-color: ${({ theme }) => theme.colors.pink};
  /* transform: translate(-50%,-50%); */

  @media screen and (max-width: 820px) {
    display: block;
    svg {
      width: 28px;
      height: 28px;
      cursor: pointer;
      fill: ${({ theme }) => theme.colors.menuMobileText};
    }
  }
`;

export const UListMobile = styled.ul`
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  list-style: none;
  display: flex;
  padding-left: 0;
`;

export const LiElemMobile = styled.li`
  padding: 15px 0;
  position: relative;
  cursor: pointer;
  font-size: 24px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.menuMobileText};
  /* 
    &:nth-last-child(1) {
        padding-top: 20px;
    } */
`;

export const WrapperTimes = styled.div`
  position: absolute;
  top: 10%;
  right: 10%;
  svg {
    fill: ${({ theme }) => theme.colors.menuMobileText};
  }
`;
