import styled from "styled-components";

export const ContactWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.dark};
  margin: 0;
  padding: 0;
  position: relative;
  &:before {
    width: 146px;
    height: 146px;
    background-color: ${({ theme }) => theme.colors.red};
    content: "";
    position: absolute;
    top: 35%;
    left: -60px;
    border-radius: 50%;
    transition: transform 0.3s ease;
    @media screen and (max-width: 1210px) {
      display: none;
    }
  }
  &:hover:before {
    transform: translateX(-50%);
  }
`;

export const ContainerContact = styled.div`
  max-width: 1210px;
  margin: 0 auto;
`;

export const WrapperText = styled.div`
  color: ${({ theme }) => theme.colors.whiteAbout};
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 60px 0;
  h3 {
    font-size: 36px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.whiteAbout};
    transition: all 0.3s;
  }

  @media screen and (max-width: 1210px) {
    padding: 30px 0;
  }
`;

export const Left = styled.div`
  color: ${({ theme }) => theme.colors.whiteAbout};
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  h4 {
    margin: 0;
    font-size: 24px;
  }

  p {
    padding-right: 80px;
    font-size: 18px;
  }

  @media screen and (max-width: 1210px) {
    width: 100%;
    padding: 0 20px 30px 40px;
  }
`;
export const Right = styled.div`
  color: ${({ theme }) => theme.colors.whiteAbout};
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media screen and (max-width: 1210px) {
    width: 100%;
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 40px 0 40px;
    align-items: flex-end;

    input,
    textarea {
      margin: 20px 0 0 0;
      padding: 10px;
      font-size: 16px;
      font-family: monospace;
      width: 100%;

      &:nth-child(1) {
        margin: 0;
      }
    }

    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      width: 100%;
      padding: 10px 30px 10px 11px; /* Dodanie miejsca na strzałkę */
      font-family: monospace;
      margin: 20px 0 0 0;
      border: 1px solid #ccc;
      background-color: white;
      background-image: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4 5"><path fill="none" stroke="gray" d="M0 0l2 2 2-2"/></svg>');
      background-repeat: no-repeat;
      background-position: 97% 15px;
      background-size: 12px;
      font-size: 16px;
      color: #000;
    }

    textarea:focus,
    input:focus,
    select:focus {
      outline: none;
    }
    textarea {
      resize: none;
      height: 140px;
    }

    button {
      padding: 10px 24px;
      background-color: ${({ theme }) => theme.colors.red};
      color: ${({ theme }) => theme.colors.btnSecond};
      border: 1px solid ${({ theme }) => theme.colors.red};
      transition: all 0.4s;
      margin-top: 15px;
      font-size: 18px;
      font-weight: 600;
      cursor: pointer;

      &:hover {
        background-color: ${({ theme }) => theme.colors.darkGrey};
        color: ${({ theme }) => theme.colors.dark};
        border: 1px solid ${({ theme }) => theme.colors.dark};
      }
    }
  }
`;
export const FormWrapper = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: 100px;

  @media screen and (max-width: 1210px) {
    flex-wrap: wrap;
  }
`;
