import React, { useRef } from "react";
import emailjs from "emailjs-com";

import {
  ContactWrapper,
  ContainerContact,
  WrapperText,
  FormWrapper,
  Left,
  Right,
} from "./Contact.style";

const Contact = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_9dl2dt9", // Zastąp swoim SERVICE_ID z EmailJS
        "template_kg2am0q", // Zastąp swoim TEMPLATE_ID z EmailJS
        form.current,
        "TsGC9KN1wsSiCH2Ok" // Zastąp swoim USER_ID z EmailJS
      )
      .then(
        (result) => {
          console.log(result.text);
          document.querySelector('#textEmail').innerHTML = 'Wiadomość wysłana pomyślnie!';
        //   alert("Wiadomość wysłana pomyślnie!");
        },
        (error) => {
          console.log(error.text);
          alert("Wystąpił błąd przy wysyłaniu wiadomości.");
        }
      );
  };
  return (
    <ContactWrapper id="contact">
      <ContainerContact>
        <WrapperText>
          <h3>Kontakt</h3>
        </WrapperText>
        <FormWrapper>
          <Left>
            <h4>Napisz do mnie:</h4>
            <p>
              Masz pytania lub chcesz rozpocząć współpracę? Skontaktuj się ze
              mną, a razem stworzymy coś wyjątkowego. Czekam na Twoje wiadomości
              i chętnie odpowiem na wszystkie pytania!
            </p>
          </Left>
          <Right>
            <form ref={form} onSubmit={sendEmail}>
              <input name="name" placeholder="Imię i nazwisko"></input>
              <input name="email" placeholder="E-mail"></input>
              <select name="choose">
                <option value="Tworzenie strony internetowej">
                  Tworzenie strony internetowej
                </option>
                <option value="Optymalizacja strony internetowej">
                  Optymalizacja strony internetowej
                </option>
                <option value="Tworzenie sklepu internetowego">
                  Tworzenie sklepu internetowego
                </option>
                <option value="Optymalizacja sklepu internetowego">
                  Optymalizacja sklepu internetowego
                </option>
                <option value="Inne">Inne</option>
              </select>
              <textarea name="text" placeholder="Treść wiadomości"></textarea>
              <button>Wyślij wiadomość</button>
            </form>
            <p id="textEmail"></p>
          </Right>
        </FormWrapper>
      </ContainerContact>
    </ContactWrapper>
  );
};

export default Contact;
